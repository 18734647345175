.success-container {
    text-align: center;
    margin-top: 100px; /* Adjust as needed */
  }
  
  .success-container h1 {
    font-family: 'Montserrat';
    font-size: 40px;
    color: #000000;
}
  
  .success-container p {
    font-size: 1.2em;
    margin-top: 20px;
  }
 
  
  .buttons button {
    background-color:  #114C5F;; /* Blue background color */
    color: #fff; /* White text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Add padding */
    margin: 0 10px; /* Add margin between buttons */
    cursor: pointer; /* Add cursor pointer on hover */
    border-radius: 5px; /* Add border radius */
  }
  
  .buttons button:hover {
    background-color:  #114C5F;; /* Darker blue on hover */
  }
  
  /* Add space between buttons */
  .buttons button + button {
    margin-left: 15px; /* Adjust the left margin for spacing */
  }


.slider_insurance_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 26px;
    margin: 0 10px;
    min-width: 260px;
    text-align: center;
  }
  
  .card-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .slider-container {
    display: flex;
    flex-direction: column;
    gap: 5px; 
    overflow: hidden;
    width: 100%;
    height: 360px; 
  }
  
  .slider-section {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 100px; 
  }
  
  .slider {
    display: flex;
    animation: slide 50s linear infinite;
  }
  
  .slider-section.reverse .slider {
    animation: slide-reverse 50s linear infinite;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  @keyframes slide-reverse {
    0% {
      transform: translateX(-50%); 
    }
    100% {
      transform: translateX(0);
    }
  }
  